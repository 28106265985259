import React, { useEffect, useMemo, useState } from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { AdvFormats } from '../../../../types'
import {
	AdFormatError,
	adFormatKeysTranslation,
	useAdformatsStore,
} from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import { getAdformatsErrorsState } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats-store-selectors/getAdformatsErrorsState'
import { RedirectErrorItem } from 'src/components/_client/create-campaign/Step2-ADformats/common/components/error-details-button/RedirectErrorItem'
import { ReactComponent as Warning } from 'src/assets/images/warningRing.svg'
import s from './addAdvButton.module.scss'

type Props = {
	disabled: boolean
	advFormat: AdvFormats
	onAddFormat: () => void
	isAdaptive?: boolean
	isFormatChanged: boolean
	totalAdvVariants: number
}
export const AddAdvButton = ({
	advFormat,
	onAddFormat,
	isAdaptive,
	disabled,
	isFormatChanged,
	totalAdvVariants,
}: Props) => {
	const errors = useAdformatsStore(getAdformatsErrorsState)
	const [errorsListOpen, setErrorsListOpen] = useState(false)
	const errorsCount = useMemo(() => {
		return Object.values(errors).reduce((acc, curr) => {
			return acc + (curr ? curr.length : 0)
		}, 0)
	}, [errors])

	console.log('errors', errors)

	const [isScrolling, setIsScrolling] = useState(false)

	const RedirectToErrorList = useMemo(() => {
		const currentErrors = Object.keys(errors).filter(
			(key) => errors[key]?.length
		)

		const renderList = currentErrors.map((key) => {
			const adError: AdFormatError[] = errors[key]
			return (
				<RedirectErrorItem
					key={key}
					adErrors={adError}
					adName={adFormatKeysTranslation[key]}
				/>
			)
		})

		return (
			<Popover
				id="step2_errors_overlay"
				style={{
					background: 'white',
					boxShadow: '0px 0px 20px rgba(33, 37, 41, 0.1)',
					border: 'none',
					minWidth: '300px',
					display: 'flex',
					flexDirection: 'column',
					gap: '4px',
					padding: '0.5rem',
					zIndex: 111,
					position: 'fixed',
				}}
			>
				{renderList}
			</Popover>
		)
	}, [errors])

	const handleAddFormat = () => {
		if (errorsCount > 0) {
			setErrorsListOpen((prev) => !prev)
			return
		}

		onAddFormat()
	}

	useEffect(() => {
		if (!isAdaptive) {
			return
		}
		let scrollTimer: NodeJS.Timeout | null = null
		const handleScroll = () => {
			if (!isScrolling) {
				setIsScrolling(true)
				setErrorsListOpen(false)
			}

			if (scrollTimer !== null) {
				clearTimeout(scrollTimer)
			}

			scrollTimer = setTimeout(() => {
				setIsScrolling(false)
			}, 150)
		}

		window.document.addEventListener('scroll', handleScroll)
		return () => {
			window.document.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const addAdvButtonTitle = useMemo(() => {
		if (totalAdvVariants === 0) {
			return advFormat === AdvFormats.Indoor
				? 'Добавьте места'
				: 'Сохранить'
		}

		return 'Добавить в кампанию'
	}, [isFormatChanged, totalAdvVariants])

	const addDisabled = disabled || !isFormatChanged

	useEffect(() => {
		if (errorsCount === 0) {
			setErrorsListOpen(false)
		}
	}, [errorsCount])
	return (
		<OverlayTrigger
			key={'top'}
			placement={'top'}
			overlay={RedirectToErrorList}
			show={errorsListOpen}
			popperConfig={{ strategy: 'fixed' }}
		>
			<Button
				variant={'primary'}
				onClick={handleAddFormat}
				className={s.addAdvButton}
				disabled={addDisabled}
			>
				{addAdvButtonTitle}
				{errorsCount > 0 && <Warning style={{ fill: '#DC3545' }} />}
			</Button>
		</OverlayTrigger>
	)
}
