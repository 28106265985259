import React, { useMemo } from 'react'
import { AdvFormats } from '../../../types'
import { Card } from 'react-bootstrap'
import useCampaignData from 'src/stores/campaignData'
import { getAdvFormatsSelectorsData } from '../../../utils'
import { FormatsSelectorItem } from './FormatsSelectorItem'
import s from './formatsSelector.module.scss'

type Props = {
	onSelectFormat: (format: AdvFormats) => void
}
export const FormatsSelector = ({ onSelectFormat }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const selectors = useMemo(() => {
		return getAdvFormatsSelectorsData(campaign)
	}, [campaign])

	return (
		<Card className={s.selectorsCard}>
			<div className={s.selectorsHead}>
				<h2>Форматы рекламы</h2>
				<p>Выберите подходящий для вас формат рекламы</p>
			</div>
			<div className={s.selectorsList}>
				{selectors.map((selector) => (
					<FormatsSelectorItem
						key={selector.key}
						selector={selector}
						onSelectFormat={onSelectFormat}
					/>
				))}
			</div>
		</Card>
	)
}
