import React from 'react'
import { InternetVariantSelector } from './internet-variant-selector/InternetVariantSelector'
import { InternetVariants } from '../../../../../../types'
import s from './internetVariantsSelectors.module.scss'

type Props = {
	isLoading: boolean
	onSelectorClick: (variant: InternetVariants) => void
	selectedVariants: InternetVariants[]
}
export const InternetVariantsSelectors = ({
	isLoading,
	onSelectorClick,
	selectedVariants,
}: Props) => {
	return (
		<div className={s.selectors}>
			<InternetVariantSelector
				onClick={onSelectorClick}
				isSelected={selectedVariants.includes(InternetVariants.Yandex)}
				selectorKey={InternetVariants.Yandex}
				isLoading={isLoading}
				title="Яндекс"
				description={
					'Авито, Mail.ru, Яндекс.Новости, Яндекс.Дзен, Яндекс.Спорт, Gismeteo и другие'
				}
			/>

			<InternetVariantSelector
				onClick={onSelectorClick}
				isSelected={selectedVariants.includes(InternetVariants.Vk)}
				selectorKey={InternetVariants.Vk}
				isLoading={isLoading}
				title="VK"
				description={'ВКонтакте, Одноклассники, Юла, Mail.ru, MyTarget'}
			/>
		</div>
	)
}
