import React, { useEffect, useMemo, useState } from 'react'
import { DistrictBookingsType } from 'src/stores/ADMarketTypes.types'
import { SingleValue } from 'react-select'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { OpenMapButton } from './OpenMapButton'
import { MassCoverageItemControl } from './MassCoverageItemControl'
import useDebounce from 'src/utils/useDebounce'
import useCampaignData from 'src/stores/campaignData'
import { DistrictType } from '../../../../types'
import s from './massCoverageItem.module.scss'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'

type OptionType = { label: string; value: number }

type Props = {
	setBudgetObjects: (
		id: number,
		newAmount: string,
		action: 'remove' | 'add'
	) => void
	setGlobalIsLoading: (isLoading: boolean) => void
	districtBooking: DistrictBookingsType | undefined
	onModalMapOpen: (data: {
		cityId: number
		amount: string
		districtBooking?: DistrictBookingsType
	}) => Promise<void>
	districts: Array<DistrictType>
	freeDistricts: Array<DistrictType>
	type: 'template' | 'item'
	onDelete: (districtBookingId: number | undefined) => Promise<void>
	onCityChange: (
		currentCityId: number | undefined,
		selectedCityId: number | undefined
	) => void
}

const massCoverageItemTemplateId = 'template'

export const MassCoverageItem = ({
	onModalMapOpen,
	districts,
	districtBooking,
	type,
	freeDistricts,
	onCityChange,
	onDelete,
	setGlobalIsLoading,
	setBudgetObjects,
}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const massCoverageItemId = `district_booking_${
		districtBooking?.id || massCoverageItemTemplateId
	}`

	const removeAdFormateByErrorParentId = useAdformatsStore(
		(state) => state.removeAdFormateByErrorParentId
	)

	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)

	const isMostExpensiveCityInitNeeded =
		!!campaign.most_expensive_city?.id &&
		type === 'template' &&
		campaign.districtbookings.length === 0

	const options: OptionType[] = useMemo(() => {
		return freeDistricts.map((el) => {
			return { label: el.title, value: el.id }
		})
	}, [freeDistricts])

	const [city, setCity] = useState(() => {
		if (isMostExpensiveCityInitNeeded) {
			const cityFromList = districts.find(
				(el) => el.id === campaign.most_expensive_city?.id
			)
			return cityFromList
		}
		return districts.find((el) => el.id === districtBooking?.city_id)
	})

	const [budget, setBudget] = useState<string>(() => {
		if (isMostExpensiveCityInitNeeded) {
			return campaign.most_expensive_city?.min_budget || ''
		}
		if (districtBooking) {
			return districtBooking.amount
		}
		return ''
	})
	const debouncedBudget = useDebounce(budget, 300) as string

	const [errors, setErrors] = useState<{
		city: string | undefined
		budget: string | undefined
	}>(() => {
		let budgetError: string | undefined = undefined
		const cityError = city ? undefined : 'Выберите город'

		if (!cityError) {
			const minBudget = city?.min_budget || '0'
			if (+minBudget > +budget) {
				budgetError = `Минимальный бюджет: ${IntlFormatter.format(
					minBudget
				)}`
			}
		}

		return {
			city: cityError,
			budget: budgetError,
		}
	})

	const handleCityChange = (newValue: SingleValue<OptionType>) => {
		let budgetError: string | undefined = undefined
		const newCity = districts.find((el) => el.id === newValue?.value)
		if (newCity) {
			onCityChange(city?.id, newCity.id)
			setBudget(newCity.min_budget)
			setCity(newCity)
		}
		setErrors({ city: undefined, budget: budgetError })
	}
	const handleBudgetChange = (e) => {
		const newBudget = e.target.value
			?.replaceAll(' ', '')
			?.replace('₽', '')
			?.replace(',', '.')
		setBudget(newBudget)

		setBudgetObjects(districtBooking?.id || 0, newBudget, 'add')
		const minBudget = city?.min_budget || '0'
		if (+minBudget > +newBudget) {
			const budgetError = `Минимальный бюджет: ${IntlFormatter.format(
				minBudget
			)}`
			setErrors({ city: errors.city, budget: budgetError })
		} else {
			setErrors({ city: errors.city, budget: undefined })
		}
	}

	const handleDelete = () => {
		onCityChange(city?.id, undefined)
		onDelete(districtBooking?.id)
	}
	const validate = () => {
		let budgetError: string | undefined = errors.budget
		let cityError: string | undefined = errors.city
		if (city) {
			cityError = undefined

			if (Number(city.min_budget) > Number(budget)) {
				budgetError = `Минимальный бюджет: ${IntlFormatter.format(
					city.min_budget
				)}`
			}
		} else {
			cityError = 'Выберите город'
		}
		const err = { city: cityError, budget: budgetError }
		setErrors(err)
		return err
	}

	const count = useMemo(() => {
		//количество мест в данном городе
		if (districtBooking && city?.id === districtBooking?.city_id) {
			return districtBooking.count_placements
		} else {
			return 0
		}
	}, [city, districtBooking])

	const handleUpdateBudget = async () => {
		if (
			type === 'item' &&
			debouncedBudget !== districtBooking?.amount &&
			city?.id === districtBooking?.city_id &&
			!errors.budget
		) {
			setGlobalIsLoading(true)
			const result = await campaignInterface.updateDistrictBooking(
				{
					districtId: districtBooking?.id!,
					amount: debouncedBudget,
				},
				false
			)

			if (campaign.district_adv_is_selected) {
				await campaignInterface.patchCampaign(campaign.id, {
					district_adv_is_selected: false,
				})
			}

			await campaignInterface.refetchSelected()
			if (result?.error?.amount?.length) {
				setErrors({ ...errors, budget: result.error.amount[0] })
			}

			setGlobalIsLoading(false)
		}
	}
	useEffect(() => {
		handleUpdateBudget()
	}, [debouncedBudget])
	useEffect(() => {
		if (districts?.length) {
			if (isMostExpensiveCityInitNeeded) {
				setCity(
					districts.find(
						(el) => el.id === campaign.most_expensive_city?.id
					)
				)
			} else {
				setCity(
					districts.find((el) => el.id === districtBooking?.city_id)
				)
			}
		}
	}, [districts])

	useEffect(() => {
		const error = validate()

		if (!!error.budget) {
			addNewAdFormatError('massCoverage', {
				field: 'Бюджет',
				id: `${massCoverageItemId}_budget`,
				message: error.budget,
				parentId: massCoverageItemId,
			})
		}
		if (!!error.city) {
			addNewAdFormatError('massCoverage', {
				field: 'Город',
				id: `${massCoverageItemId}_city`,
				message: error.city,
				parentId: massCoverageItemId,
			})
		}

		if (count === 0) {
			addNewAdFormatError('massCoverage', {
				field: 'Уточните места',
				id: `${massCoverageItemId}_placementsCount`,
				message: 'Проверьте выбранные места',
				parentId: massCoverageItemId,
			})
		}

		return () => {
			removeAdFormateByErrorParentId('massCoverage', massCoverageItemId)
		}
	}, [city, debouncedBudget, districtBooking])

	return (
		<div className={s.massCoverageItem}>
			<MassCoverageItemControl
				districtBookingId={massCoverageItemId}
				recBudget={districtBooking?.rec_budget}
				minBudget={city?.min_budget || ''}
				onDelete={handleDelete}
				budget={budget}
				city={city}
				errors={errors}
				onBudgetChange={handleBudgetChange}
				onCityChange={handleCityChange}
				options={options}
			/>

			<OpenMapButton
				districtBookingId={massCoverageItemId}
				onDelete={handleDelete}
				count={count}
				errors={errors}
				onMapOpen={() =>
					onModalMapOpen({
						cityId: city?.id || 0,
						districtBooking,
						amount: budget,
					})
				}
			/>
		</div>
	)
}
