import React, { FC, useEffect } from 'react'
import s from './CampaignInfo.module.scss'
import useResponsive from '../../../utils/useResponsive'
import MobileFixedHeader from './MobileFixedHeader'
import CampaignMessage from './CampaignMessage'
import CampaignStatusAndButtons from './CampaignStatusAndButtons'
import CampaignTitle from './CampaignTitle'
import CampaignDuration from './CampaignDuration'
import CampaignTypeSwitcher from './common/campaign-type-switcher/CampaignTypeSwitcher'
import PaymentBottom from './payment/PaymentBottom'
import PaymentTimeProgress from './payment/PaymentTimeProgress'
import PaymentResultModal from './payment/PaymentResultModal'
import { useCampaignInfoStore } from './store/campaignInfo.store'
import { useTechnicalRequirementsStore } from 'src/stores/technical-requirements-store'
import useCampaignData from 'src/stores/campaignData'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import { CampaignReportCard } from './common/campaign-report/CampaignReportCard'

const CampaignInfo: FC = () => {
	const { isMobile } = useResponsive()

	const [campaign, campaignInterface] = useCampaignData()

	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)
	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const resetAdvFormats = useTechnicalRequirementsStore(
		(state) => state.resetAdvFormats
	)
	const resetVinstantCreative = useCampaignInfoStore(
		(state) => state.resetVinstantCreative
	)

	useEffect(() => {
		if (campaign?.id) {
			;(async () => {
				await fetchVinstantCreative(campaign.id)
				await fetchTechnicalRequirements(campaign)
			})()
		}

		return () => {
			resetAdvFormats()
			resetVinstantCreative()
		}
	}, [])
	return (
		<>
			{isMobile && <MobileFixedHeader />}
			<section className={s.container}>
				<CampaignMessage />
				<CampaignStatusAndButtons />
				<CampaignTitle />
				<PaymentTimeProgress />
				<CampaignDuration />

				{campaign.state === CampaignStates.Completed && (
					<CampaignReportCard campaignId={campaign.id} />
				)}
				<CampaignTypeSwitcher />
				<PaymentBottom />
			</section>
			<PaymentResultModal />
		</>
	)
}

export default CampaignInfo
