import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { DistrictBookingsType } from 'src/stores/ADMarketTypes.types'
import { MassCoverageModalMap } from '../modals'
import {
	fetchDistricBookingDetails,
	fetchMassCoveragePlacements,
} from '../../../utils'
import { DistrictType, MapDataType } from '../../../types'
import useCampaignData from 'src/stores/campaignData'
import { CreateMassCoverageDistrictBookingBody } from 'src/stores/types/districtBookingTypes'
import { MassCoverageStatistic } from './MassCoverageStatistic'
import { MassCoverageItem } from './mass-coverage-item'
import s from './massCoverageItemsList.module.scss'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

type MassCoverageItemsListProps = {
	isTemplate: boolean
	setIsTemplate: (isTemplate: boolean) => void
	setGlobalIsLoading: (isLoading: boolean) => void
	districtbookings: DistrictBookingsType[]
	districts: DistrictType[]
}

export const MassCoverageItemsList = ({
	districtbookings,
	districts,
	isTemplate,
	setIsTemplate,
	setGlobalIsLoading,
}: MassCoverageItemsListProps) => {
	const [campaign, campaignInterface] = useCampaignData()

	const { addToast } = useContext(ToastsContext)

	const [freeDistricts, setFreeDistricts] = useState<DistrictType[]>([])
	const [modalMapOpen, setModalMapOpen] = useState(false)
	const [mapData, setMapData] = useState<MapDataType | undefined>(undefined)
	const [budgetObject, setBudgetObject] = useState<
		{ id: number; amount: string }[]
	>(() => {
		return districtbookings.map((el) => {
			return { id: el.id, amount: el.amount }
		})
	})

	const handleMapOpen = async (data: {
		cityId: number
		amount: string
		districtBooking?: DistrictBookingsType
	}) => {
		const { amount, cityId, districtBooking } = data
		try {
			setGlobalIsLoading(true)
			const [placementsResult, districtBookingResult] = await Promise.all(
				[
					fetchMassCoveragePlacements(cityId),
					districtBooking?.city_id === cityId
						? fetchDistricBookingDetails(districtBooking.id)
						: Promise.resolve(null),
				]
			)

			const defaultCenter: [number, number] = [
				+placementsResult[0]?.lat || 0,
				+placementsResult[0]?.lon || 0,
			]
			const center: [number, number] =
				districtBookingResult?.center_lat &&
				districtBookingResult?.center_lon
					? [
							+districtBookingResult.center_lat,
							+districtBookingResult.center_lon,
					  ]
					: defaultCenter

			const minRadius = 500
			const radius =
				districtBookingResult?.radius &&
				+districtBookingResult.radius >= minRadius
					? +districtBookingResult.radius
					: minRadius

			const district = districts.find((el) => el.id === cityId)
			const mapData: MapDataType = {
				cityName: district?.title || '',
				placements: placementsResult,
				center,
				radius,
				currentPlacements: districtBookingResult?.placements || [],
				amount,
				cityId,
				districtBookingId: districtBooking?.id,
			}

			setMapData(mapData)
			setModalMapOpen(true)
		} catch (error) {
			console.error('Ошибка при загрузке карты:', error)
		} finally {
			setGlobalIsLoading(false)
		}
	}
	const handleClose = () => {
		setMapData(undefined)
		setModalMapOpen(false)
	}

	const handlFreeDistrictChange = (
		currentCityId: number | undefined,
		selectedCityId: number | undefined
	) => {
		//case for removing item
		if (currentCityId && !selectedCityId) {
			const returnedDistrict = districts.find(
				(el) => el.id === currentCityId
			)
			if (returnedDistrict) {
				setFreeDistricts([returnedDistrict!, ...freeDistricts])
			}
			return
		}
		//case for template city select
		if (!currentCityId) {
			setFreeDistricts(
				freeDistricts.filter((el) => el.id !== selectedCityId)
			)
			return
		}

		//case for switching city in exsisted districtbooking
		const newFreeDistricts = freeDistricts.filter(
			(el) => el.id !== selectedCityId
		)
		const returnedDistrict = districts.find((el) => el.id === currentCityId)
		if (returnedDistrict) {
			setFreeDistricts([returnedDistrict!, ...newFreeDistricts])
		}
	}

	const handleBudgetObjectsChange = (
		id: number,
		newAmount: string,
		action: 'remove' | 'add'
	) => {
		if (action === 'remove') {
			const filteredBudgetObject = budgetObject.filter(
				(el) => el.id !== id
			)
			setBudgetObject(filteredBudgetObject)
		}
		if (action === 'add') {
			const newBudgetObject = budgetObject.map((el) => {
				if (el.id === id) {
					return { id, amount: newAmount }
				}
				return el
			})
			setBudgetObject(newBudgetObject)
		}
	}
	const handelDistrictBookingDelete = async (
		districtBookingId: number | undefined
	) => {
		if (!districtBookingId) {
			setIsTemplate(false)
		} else {
			await campaignInterface.removeDistrictBooking(districtBookingId)
			handleBudgetObjectsChange(districtBookingId || 0, '0', 'remove')
		}
	}
	const handleErrorResponse = (error: { [key: string]: string[] }) => {
		const allErrors = Object.values(error)?.flatMap((el) => el) as string[]
		addToast({
			text: allErrors?.join(' ,'),
			type: 'danger',
		})
	}
	const handleSubmit = async (
		body: CreateMassCoverageDistrictBookingBody,
		districtBookingId: number | undefined
	) => {
		setGlobalIsLoading(true)
		//update current
		if (districtBookingId) {
			const result = await campaignInterface.updateDistrictBooking(
				{
					...body,
					districtId: districtBookingId,
				},
				false
			)

			if (typeof result?.error === 'object') {
				handleErrorResponse(result.error)
				setGlobalIsLoading(false)
				return
			}
		} else {
			//create new one
			const result = await campaignInterface.addDistrictBooking(body)
			if (typeof result?.error === 'object') {
				handleErrorResponse(result.error)
				setGlobalIsLoading(false)
				return
			}
		}
		if (campaign.district_adv_is_selected) {
			await campaignInterface.patchCampaign(campaign.id, {
				district_adv_is_selected: false,
			})
		}

		await campaignInterface.refetchSelected()
		setGlobalIsLoading(false)
		setIsTemplate(false)
		handleClose()
	}

	const placementsCount = useMemo(() => {
		return districtbookings.reduce((acc, el) => {
			return acc + el.count_placements
		}, 0)
	}, [districtbookings])

	const budgetSummary = useMemo(() => {
		return budgetObject.reduce((acc, el) => {
			return acc + +el.amount
		}, 0)
	}, [budgetObject])

	useEffect(() => {
		setBudgetObject(
			districtbookings.map((el) => {
				return {
					amount: el.amount,
					id: el.id,
				}
			})
		)
	}, [districtbookings])
	useEffect(() => {
		const templateBudgetId = 0
		if (isTemplate) {
			setBudgetObject([
				...budgetObject,
				{ amount: '0', id: templateBudgetId },
			])
		} else {
			const filteredBudgetObject = budgetObject.filter(
				(el) => el.id !== templateBudgetId
			)
			setBudgetObject(filteredBudgetObject)
		}
	}, [isTemplate])
	useEffect(() => {
		const occupiedDistrictIds = districtbookings.map((el) => el.city_id)
		setFreeDistricts(
			districts.filter((el) => !occupiedDistrictIds.includes(el.id))
		)
	}, [districts])
	return (
		<div className={s.massCoverageItemsList}>
			<>
				{districtbookings?.map((districtBooking) => (
					<MassCoverageItem
						setBudgetObjects={handleBudgetObjectsChange}
						setGlobalIsLoading={setGlobalIsLoading}
						onDelete={handelDistrictBookingDelete}
						type="item"
						districtBooking={districtBooking}
						freeDistricts={freeDistricts}
						districts={districts}
						onCityChange={handlFreeDistrictChange}
						onModalMapOpen={handleMapOpen}
						key={districtBooking.id}
					/>
				))}
				{isTemplate && (
					<MassCoverageItem
						setBudgetObjects={handleBudgetObjectsChange}
						setGlobalIsLoading={setGlobalIsLoading}
						onDelete={handelDistrictBookingDelete}
						onCityChange={handlFreeDistrictChange}
						freeDistricts={freeDistricts}
						type="template"
						key={'mass-coverage-item-template'}
						districtBooking={undefined}
						districts={districts}
						onModalMapOpen={handleMapOpen}
					/>
				)}
			</>
			<Button
				disabled={isTemplate}
				onClick={() => setIsTemplate(true)}
				className={s.addCityButton}
			>
				<i className="bi bi-plus-lg me-2" />
				Добавить еще город
			</Button>
			{(districtbookings.length !== 0 || isTemplate) && (
				<MassCoverageStatistic
					budgetSummary={budgetSummary}
					placementsCount={placementsCount}
				/>
			)}

			{modalMapOpen && mapData && (
				<MassCoverageModalMap
					onSubmit={handleSubmit}
					mapData={mapData}
					open={modalMapOpen}
					onClose={handleClose}
				/>
			)}
		</div>
	)
}
